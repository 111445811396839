import NextI18Next from 'next-i18next'
import getConfig from 'next/config'
import { TransProps } from 'react-i18next'
import path from 'path'

// @FIXME Next config empty in test environent. Don't know why.
const ALLOWED_LOCALES = process.env.NODE_ENV === 'test' ? ['cs'] : getConfig().publicRuntimeConfig.ALLOWED_LOCALES

/**
 * For next-i18next options see:
 * https://github.com/isaachinman/next-i18next#options
 *
 * For i18next options see:
 * https://www.i18next.com/overview/configuration-options
 * https://github.com/i18next/i18next/blob/master/src/defaults.js
 */
const nexti18next = new NextI18Next({
  /**
   * next-i18next options
   */
  defaultLanguage: ALLOWED_LOCALES[0],
  otherLanguages: ALLOWED_LOCALES,
  localePath: path.resolve('static/locales'),
  localeStructure: '{{lng}}/{{ns}}',
  browserLanguageDetection: true,
  serverLanguageDetection: true,

  /**
   * i18next options
   */
  detection: {
    order: ['cookie', 'header'],
    lookupCookie: 'terapio-lang',
    caches: ['cookie']
  },
  fallbackLng: 'en',
  defaultNS: 'web',
  saveMissing: false,
  whitelist: ALLOWED_LOCALES,
  react: {
    // https://react.i18next.com/latest/migrating-v9-to-v10#migration
    useSuspense: false,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b'],
  },
  lowerCaseLng: true,
})

const { appWithTranslation, i18n, useTranslation, withTranslation } = nexti18next
const Trans: (props: TransProps<string>) => any = nexti18next.Trans

export default nexti18next
export { appWithTranslation, i18n, useTranslation, Trans, withTranslation }
